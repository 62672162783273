import type {
  FieldValidation,
  FieldValidationEntity,
  FieldValidationValidate,
} from './types'

export const useFieldValidation = <R>(
  rules: FieldValidationEntity[],
): FieldValidation<R> => {
  const error = ref<string | string[]>('')

  const validate: FieldValidationValidate<R> = (value): value is R => {
    error.value = ''

    const invalidRule = rules.find(([rule]) => !rule(value))

    if (invalidRule) {
      const invalidRuleMessage = invalidRule[1]

      error.value =
        typeof invalidRuleMessage === 'function'
          ? invalidRuleMessage()
          : invalidRuleMessage

      return false
    }

    return true
  }

  return { error, validate }
}
